
  import { defineComponent, ref } from "vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import dateFormat from "@/utils/dateFormat";
  import Empty from "../common/Empty.vue";
  import qs from "qs";
  export default defineComponent({
    components: { Empty },
    name: "Message",
    setup() {
      const my = ref(null);
      const guestbookList = ref(null);

      axios
              .get("/M/Home/GuestBug", { params: { validate: true } })
              .then((res) => {
                if (res.data.success) {
                  my.value = res.data.obj.my;
                  res.data.obj.guestbookList.forEach((item: any) => {
                    item.imgUrls = item.imgUrls ? item.imgUrls.split(",") : [];
                    item.expand = true;
                  });

                  guestbookList.value = res.data.obj.guestbookList;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log('err')
              });
      function deleteMessage(id: string, index: number) {
        axios
                .post(
                        "/M/Home/DelGuestBug",
                        qs.stringify({
                          id,
                        }),
                        { params: { validate: true } }
                )
                .then((res) => {
                  if (res.data.success) {
                    (guestbookList.value as any).splice(index, 1);
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err)
                });
      }
      function switchExpand(index: number) {
        if (guestbookList.value) {
          (guestbookList.value as any)[
                  index
                  ].expand = !(guestbookList.value as any)[index].expand;
        }
      }
      return {
        my,
        guestbookList,
        dateFormat,
        switchExpand,
        deleteMessage
      };
    },
  });
