
  import { defineComponent, ref } from "vue";
  import MenuTab from "@/components/common/MenuTab.vue";
  import Question from "@/components/Suggest/Question.vue";
  import Message from "@/components/Suggest/Message.vue";
  import { useRoute, useRouter} from 'vue-router'
  export default defineComponent({
    name: "Suggest",
    components: {
      MenuTab,
      Question,
      Message,
    },
    setup() {
      //  切换列表菜单
      type Menu = "提交问题"  | "留言记录";
      const menu: Menu[] = ["提交问题", "留言记录"];
      const route = useRoute()
      const type = Number(route.query.type || 0);

      const currentMenuItem = ref(menu[Number(type)]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      return {
        menu,
        type,
        currentMenuItem,
        selectMenu,
      };
    },
  });
